<template>
<div v-if="loading">
    <Loading :active.sync="loading" color="#042446"></Loading>
</div>
<div v-else>
    <Carousel />
    <div class="homepage">
        <b-row class="justify-content-xl-center">
            <b-col xl="2" class="pl-0 pr-4 marginTop" v-if="$vssWidth>=1240">
                <SidebarMenu :menuLists="menuLists" />
                <TopTenReader />
            </b-col>

            <b-col xl="7" cols="12" class="novelList">

                <TopicOnHomepage v-if="lestestPromotionNovel.length != 0" :topics="topics[0]" />
                <NovelList v-if="lestestPromotionNovel.length != 0" :novelTypes="novelTypes[0]" />

                <TopicOnHomepage :topics="topics[1]" />
                <NovelList :novelTypes="novelTypes[1]" />
                
                <TopicOnHomepage :topics="topics[2]" />
                <NovelList :novelTypes="novelTypes[2]" />

                <TopicOnHomepage :topics="topics[3]" />
                <TopTenNovelList :novelTypes="novelTypes[3]" />

                <TopicOnHomepage :topics="topics[4]" />
                <TopTenNovelList :novelTypes="novelTypes[4]" />

                <TopicOnHomepage :topics="topics[5]" />
                <NovelList :novelTypes="novelTypes[5]" />
            </b-col>

            <b-col xl="3" class="topTenReader marginTop" v-if="$vssWidth>=1240">
                <TopNovelGender />
            </b-col>
        </b-row>
    </div>

</div>
</template>

<script>
const Carousel = resolve => {
    require.ensure(['./../elements/Carousel'], () => {
        resolve(require('./../elements/Carousel.vue'))
    })
}
const SidebarMenu = resolve => {
    require.ensure(['./../elements/SidebarMenu'], () => {
        resolve(require('./../elements/SidebarMenu.vue'))
    })
}
const TopTenReader = resolve => {
    require.ensure(['./../elements/TopTenReader'], () => {
        resolve(require('./../elements/TopTenReader.vue'))
    })
}
const TopicOnHomepage = resolve => {
    require.ensure(['./../elements/TopicOnHomepage'], () => {
        resolve(require('./../elements/TopicOnHomepage.vue'))
    })
}
const NovelList = resolve => {
    require.ensure(['./../elements/NovelList'], () => {
        resolve(require('./../elements/NovelList.vue'))
    })
}
const TopTenNovelList = resolve => {
    require.ensure(['./../elements/TopTenNovelList'], () => {
        resolve(require('./../elements/TopTenNovelList.vue'))
    })
}
const TopNovelGender = resolve => {
    require.ensure(['./../elements/TopNovelGender'], () => {
        resolve(require('./../elements/TopNovelGender.vue'))
    })
}

import {
    mapGetters,
    mapActions
} from 'vuex'

export default {
    name: "Homepage",
    mounted() {
        this.SET_LOADING_STATUS(true)
    },
    computed: {
        ...mapGetters({
            loading: 'GET_LOADING',
            lestestPromotionNovel: 'GET_LESTEST_PROMOTION_NOVEL',
        })
    },
    components: {
        Carousel,
        SidebarMenu,
        TopTenReader,
        TopicOnHomepage,
        NovelList,
        TopTenNovelList,
        TopNovelGender
    },
    data() {
        return {
            menuLists: [
                {
                    icon: '',
                    src: 'facebook2.png',
                    rotate: '0',
                    menuName: 'Facebook'
                },
                {
                    icon: 'chat-square',
                    src: '',
                    rotate: '0',
                    menuName: 'ชุมชนชาวยุทธ'
                },
                {
                    icon: 'volume-up',
                    src: '',
                    rotate: '-45',
                    menuName: 'ร้องทุกข์ชาวยุทธ'
                },
            ],
            topics: [
                {
                    topicName: 'นิยายจัดโปรโมชัน',
                    seeAllBtn: true,
                    type: 'promotionNovel'
                },
                {
                    topicName: 'นิยายแปลตอนล่าสุด',
                    seeAllBtn: true,
                    type: 'translateNovel'
                },
                {
                    topicName: 'นิยายแต่งตอนล่าสุด',
                    seeAllBtn: true,
                    type: 'createNovel'
                },
                {
                    topicName: '100 อันดับนิยายแปลยอดฮิต',
                    seeAllBtn: false,
                    type: 'topTenTranslate'
                },
                {
                    topicName: '100 อันดับนิยายแต่งยอดฮิต',
                    seeAllBtn: false,
                    type: 'topTenCreate'
                },
                {
                    topicName: 'นิยายทั้งหมด',
                    seeAllBtn: true,
                    type: 'allNovel'
                },
            ],
            novelTypes: ['promotionNovel', 'translateNovel', 'createNovel', 'topTenTranslate', 'topTenCreate', 'allNovel']
        }
    },
    metaInfo() {
        return { 
            title: "NovelRealm ศูนย์รวมนิยายแปล นิยายแต่ง ขึ้นหิ้งมากมาย",
            meta: [
                { name: 'description', content:  'NovelRealm นิยายแปลที่ถูกคัดสรรมาอย่างดี การันตีด้วยยอดผู้อ่านกว่าล้านครั้ง ทั้งนิยายแฟนตาซี นิยายกำลังภายใน นิยายรักโรแมนติก'},
                { property: 'og:title', content: "NovelRealm ศูนย์รวมนิยายแปล นิยายแต่ง ขึ้นหิ้งมากมาย"}
            ]
        }
    },
    async created() {
        this.SET_UPDATE_NOVEL()
        this.SET_ALL_NOVEL()
        this.SET_TOPTEN_NOVEL()
    },
    methods: {
        ...mapActions(['SET_UPDATE_NOVEL', 'SET_ALL_NOVEL', 'SET_TOPTEN_NOVEL', 'SET_LOADING_STATUS']),
    }
}
</script>

<style scoped>


.homepage {
    padding: 1% 5% 1% 5%;
}

.marginTop {
    margin-top: 2%;
}

.topTenReader {
    padding-left: 3%;
    padding-right: 0%;
}

@media screen and (max-width: 500px) {
    .homepage {
        padding: 0% 5% 0.5% 5%;
    }

    .topTenReader {
        padding-left: 0%;
        padding-right: 0%;
    }

    .novelList {
        padding: 0px;
    }
}
@media screen and (min-width: 1600px) {
    .homepage {
        padding: 1% 20% 1% 20%;
    }
}

</style>
